import { Badge } from "@mui/material";
import "./DriverCategoryBadge.scss";
import { CDataTypography } from "../text/CDataTypography";
import { DriverCategoryNames } from "src/utility/enums/DriverCategoryNames";

interface IDriverCategoryBadge {
  driverCategory: string;
}

export const DriverCategoryBadge = (props: IDriverCategoryBadge) => {
  const { driverCategory } = props;

  const categoryName =
    DriverCategoryNames[driverCategory as keyof typeof DriverCategoryNames];

  return (
    <Badge className="driver-category-badge">
      {driverCategory === "C" && <i className="fa-solid fa-star fa-xs" />}
      <CDataTypography
        variant="typography-variant-caption"
        color="typography-color-white"
      >
        {categoryName}
      </CDataTypography>
    </Badge>
  );
};
